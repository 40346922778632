import {Context, createContext} from "react";

export interface StoreContextType {
  enableCart: boolean;
  setEnableCart: (value: boolean) => void;
}
export const StoreContext: Context<StoreContextType> = createContext<StoreContextType>({
  enableCart: false,
  setEnableCart: () => {}
});
