import {default as NextLink} from 'next/link';

export const isExternalURL = (url) => {
  return !(url.startsWith('/') || url.startsWith(process?.env?.NEXT_PUBLIC_SITE_URL));
};

export function Link({ href, children, ...props}) {

  if (isExternalURL(href)) {
    return (
      <NextLink href={href} {...props} target="_blank" rel="noopener noreferrer">
        {children}
      </NextLink>
    );
  }

  return (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  );
}