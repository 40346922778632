import Image, { ImageProps } from 'next/image';
import { DrupalMedia } from 'next-drupal';

import { absoluteURL } from 'lib/absolute-url';

interface MediaImageProps extends Partial<ImageProps> {
  media: DrupalMedia;
  imageStyle?: string;
  childClass?: string,
  as?: string,
  wrapperClass?: string,
}

MediaImage.type = 'media--image';

export function MediaImage({
  media,
  imageStyle,
  fill = false,
  width,
  height,
  priority,
  quality,
  sizes,
  placeholder,
  blurDataURL,
  style,
  loading,
  childClass,
  wrapperClass,
  ...props
}: MediaImageProps) {
  const brandfolder = media?.type === "media--brandfolder_image";
  const image = media?.image ?? media?.bf_image;

  if (!image) {
    return null;
  }
  let sizeProps;
  let srcURL;

  sizeProps = fill
    ? null
    : {
        width: width || image.resourceIdObjMeta.width,
        height: height || image.resourceIdObjMeta.height,
      };
  srcURL = !brandfolder ? absoluteURL(image?.uri?.url) : image?.uri?.url;

  if (imageStyle && image?.image_style_uri?.[imageStyle]) {
    srcURL = image?.image_style_uri?.[imageStyle];
    // If the URL is not absolute, create it.
    if (!brandfolder && srcURL.match(/^\//)) {
      srcURL = absoluteURL(`/sites/default/files/styles/${imageStyle}${srcURL}`)
    }
  }
  // Use the image style to render an image if specified.
  else if (!brandfolder && imageStyle) {
    if (image?.links) {
      if (image?.links[imageStyle]) {
        const imageStyleSource = image.links[imageStyle];
        sizeProps = {
          width: width || imageStyleSource.meta.linkParams.width,
          height: height || imageStyleSource.meta.linkParams.height,
        };
        srcURL = imageStyleSource.href;
      } else {
        // @todo swap to use consumer image styles.
        // console.warn(
        //   `${imageStyle} image style does not exist. You must configure the consumer to include the image style to be used by this application.`,
        // );
      }
    } else {
      // @todo swap to use consumer image styles.
      // console.warn(
      //   'Image styles not found. The consumer_image_styles module must be installed and enabled for the consumer of this application.',
      // );
    }
  }

  // Default to blur if there is a blur placeholder available, alternatively, if blur is
  // set and there is no blurDataURL provided, set it accordingly.
  if ((placeholder === "blur" || placeholder === undefined) && blurDataURL === undefined) {
    // We only send the base64 hash back as it's always webp, we don't need the bulk of the
    // additional data url prefix, so we add it here.
    blurDataURL = image?.blur_image?.value?.length ? `data:webp;base64,${image.blur_image.value}` : undefined;
    placeholder = blurDataURL ? "blur" : "empty";
  }

  return (
    // <div className={classNames("media__content image__wrapper", wrapperClass ?? "")} {...props}>
    <div className={`media__content image__wrapper ${wrapperClass ?? ""}`} {...props}>
      <Image
        src={srcURL}
        alt={props?.alt ?? image.resourceIdObjMeta.alt ?? "Image"}
        title={image.resourceIdObjMeta.title}
        priority={priority}
        style={style}
        sizes={sizes}
        fill={fill}
        as={props.as ?? undefined}
        className={childClass}
        quality={quality}
        blurDataURL={blurDataURL}
        loading={loading}
        placeholder={placeholder}
        {...(!fill ? sizeProps : {})}
      />
      {props.children}
    </div>
  );
}
