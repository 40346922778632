import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import classNames from "classnames";

/**
 * Defines a layout content fade-in/fade-out transition.
 * @param children
 * @constructor
 */
export function LayoutTransition({ children }: { children?: any }) {
  const router = useRouter();
  const getPath = (path?: string) => path?.split("?")?.[0] ?? router?.asPath?.split("?")?.[0] ?? "";
  const [display, setDisplay] = useState(children);
  const [transitioning, setTransitioning] = useState(false);
  const [load, setLoad] = useState(false);
  const [path, setPath] = useState(getPath());

  const variants = {
    out: {
      opacity: 0,
      transition: {
        duration: 5,
      },
    },
    in: {
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  };

  useEffect(() => {
    // this handler will create a transition effect between route changes,
    // so that it doesn't automatically display the next screen.
    const complete = (uri: string) => {
      const currentPath = getPath(uri);
      currentPath !== path && setTransitioning(true);
      setLoad(false);
      setPath(currentPath);

      setTimeout(() => {
        setTransitioning(false);
      }, 500);
    };
    const start = (uri: string) => {
      setDisplay(children);
      setLoad(getPath(uri) !== path);
    }

    router.events.on("routeChangeStart", start);
    router.events.on("routeChangeComplete", complete);
    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", complete);
    };
  }, [children, router.events]);

  return (
    <main
      className={classNames("bg-hygienaLight text-black relative hygiena-main", {"md:animate-page-in": (!transitioning && !load), "md:animate-page-out": (transitioning && !load)})}>
      { (transitioning || load) ? display : children }
    </main>
  )
}