import { useRouter } from 'next/router';
import classNames from 'classnames';
import { DrupalMenuLinkContent } from 'next-drupal';
import {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState} from "react";
import {Button} from "../../atoms/button";
import {
  DrupalMainMenuLinkContent, TranslatedUrls,
} from "../../../types/hygiena-types";
import {SubMenu} from "../../organisms/submenu";
import {Link} from "../../atoms/link";
import {LanguageSelect} from "../../molecules/language-select";

interface MenuMainProps {
  menu?: DrupalMenuLinkContent[]|DrupalMainMenuLinkContent[];
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  translatedUrls?: TranslatedUrls | null,
}

export function HamburgerToggle({menuOpen, setMenuOpen}: {menuOpen: boolean, setMenuOpen: Dispatch<SetStateAction<boolean>>}) {

  return (
    <div className={classNames(
      "flex relative flex-col w-8 gap-1 cursor-pointer ml-4",
      "lg:hidden"
    )} onClick={() => setMenuOpen(!menuOpen)}>
      <div className="w-full h-1 bg-primary"></div>
      <div className="w-full h-1 bg-primary"></div>
      <div className="w-full h-1 bg-primary"></div>
    </div>
  )
}

export function MenuMain({ menu, menuOpen, setMenuOpen, translatedUrls, ...props }: MenuMainProps) {
  const router = useRouter();
  const defaultLocale = router.locale === router.defaultLocale;
  const localePath = '/' + router.locale + router.asPath.replace(/\/+$/, '');
  const activePath = defaultLocale ? router.asPath : localePath;
  const [menuVisible, setMenuVisible] = useState("");
  const [dropTrigger, setDropTrigger] = useState("");
  //  The active submenu is used for the responsive second level menu fly out.
  const [activeSubmenu, setActiveSubmenu] = useState('');
  const hasCTA = menu?.length ? menu?.filter(item => item?.field_menu_item_cta)?.length : false;
  const [small, setSmall] = useState<boolean>();
  const closeLanguage = useRef<MutableRefObject<Dispatch<SetStateAction<boolean|undefined>>>>();

  useEffect(() => {
    setSmall(window.innerWidth < 768);
    const resize = () => setSmall(window.innerWidth < 768);
    addEventListener("resize", resize);
    const handler = setTimeout(() => {
      if (dropTrigger !== menuVisible) {
        setMenuVisible(dropTrigger);
      }
    }, 50);
    return () => {
      clearTimeout(handler);
      removeEventListener("resize", resize);
    }
  }, [dropTrigger]);

  // When the page changes, close the menu.
  useEffect(() => {
    setMenuVisible("");
    setActiveSubmenu("");
    setMenuOpen(false);
  }, [router?.asPath]);

  if (!menu?.length) {
    return null;
  }

  /**
   * Will slide out an auxiliary menu on mobile, when a menu item has one or more submenus.
   *
   * @param event
   *   The event.
   * @param item
   *   The menu item.
   */
  function mobileCallback(event, item: DrupalMainMenuLinkContent) {
    if (item?.url?.match(/^\#/) && typeof window !== "undefined") {
      event.preventDefault();
      const element = document.getElementById(item.url.replace("#", ""));
      if (element) element.scrollIntoView({behavior: 'smooth'})
    }
    if (item?.submenu?.items?.length) {
      event.preventDefault();
      setActiveSubmenu(item.id);
    }
    else if (item?.items?.length) {
      event.preventDefault();
    }
    else {
      setMenuOpen(false);
      setActiveSubmenu("");
    }
  }

  function pathIsActive(item) {
    // Check the submenu for any matching items.
    if (item?.submenu?.items?.length) {
      for (const term of item.submenu.items) {
        const alias = term?.path?.alias;
        if (alias && activePath && activePath.match(new RegExp("^" + alias))) {
          return true;
        }
      }
    }
    // Check the utility items for a match.
    if (item?.utilityItems?.length) {
      for (const term of item.utilityItems) {
        const alias = term?.path?.alias;
        if (alias && activePath && activePath.match(new RegExp("^" + alias))) {
          return true;
        }
      }
    }
    // Check 1 level menu items.
    if (item?.items?.length) {
      for (const term of item.items) {
        const alias = term?.url;
        if (alias && activePath && activePath.match(new RegExp("^" + alias))) {
          return true;
        }
      }
    }
    return (activePath === item.url || activePath.indexOf(item.url)) == 1;
  }

  /**
   * Updates the drop trigger.
   */
  function updateDropTrigger(id: string) {
    if (typeof window === "undefined") return;
    if (window.innerWidth >= 1024) setDropTrigger(id);
  }

  return (
    <nav data-cy="nav-menu" {...props} id="main-navigation" className={classNames(
      "max-lg:fixed max-lg:h-full max-lg:w-full max-lg:bg-white max-lg:flex max-lg:justify-center",
      "max-lg:items-center max-lg:top-0 max-lg:transition-[left] max-lg:duration-500 text-black",
      "lg:flex-grow lg:pr-6 max-lg:z-10",
      {
        "max-lg:left-[100%]": !menuOpen, "max-lg:left-0 !max-lg:inset-0": menuOpen,
      },
    )}>
      <div className={classNames("absolute top-8 left-6", {"hidden": !menuOpen})}>
        <LanguageSelect small={small} closeRef={closeLanguage} translatedUrls={translatedUrls}/>
      </div>
      {/* Swap out to an icon */}
      <div
        onClick={() => {
          setMenuOpen(false)
          if (closeLanguage?.current) closeLanguage.current();
        }}
        className={classNames(
        "absolute lg:hidden top-8 right-6 text-2xl cursor-pointer",
      )}>
        <div className="h-1 w-8 bg-primary rounded-md rotate-45 absolute right-0 cursor-pointer" />
        <div className="h-1 w-8 bg-primary rounded-md -rotate-45 absolute right-0 cursor-pointer" />
      </div>
      <ul className={classNames(
        "flex flex-col items-center space-x-4 text-center text-3xl",
        "max-lg:w-full max-lg:justify-center max-lg:h-full max-lg:gap-4",
        "md:space-x-2",
        "lg:flex-row lg:justify-center lg:text-left lg:text-base"
      )}>
        {menu?.map((item, idx) => {
          const isActive = pathIsActive(item);
          const visible = menuVisible === item.id;
          const isCTA = item?.field_menu_item_cta;
          const first = idx === 0;
          return (
            <li
              key={item.id}
              className={classNames("menu-item font-semibold uppercase lg:normal-case", {
                "menu-item--active-trail": isActive,
                "lg:!ml-auto": first && hasCTA,
                "lg:!ml-auto btn-header-cta": isCTA,
                "has-submenu": item?.submenu?.items?.length,
              })}
              onMouseEnter={() => updateDropTrigger(item.id)}
              onClick={(event) => {
                if (event?.target?.["classList"]?.contains("submenu-link")) {
                }
                else {
                  setDropTrigger(dropTrigger !== item.id ? item.id : "");
                }
              }}
              onMouseLeave={() => updateDropTrigger("")}
            >
              <Button key={item.id} href={item.url}
                      callback={(event) => mobileCallback(event, item)}
                      icon={(item?.items || item?.submenu?.items?.length) && "chevron-down"}
                      // Chevron for dropdowns with a submenu on mobile only.
                      responsiveIcon={true}
                      hover={{color: "white"}}
                      color={(visible || isActive || isCTA) ? "primary" : "black"}
                      iconColor={(visible) ? "white" : (isActive ? "primary" :  "black")}
                      rotateIcon={visible}
                      className={classNames(
                        "transition-all duration-300 uppercase lg:normal-case",
                        {"max-[1200px]:hidden ml-3": isCTA},
                        {"border-transparent": !visible},
                        {"text-primary": isActive && !visible && !isCTA},
                      )}
                      position={"right"} invertColor={"white"} invert={(!visible && !isCTA) || (visible && isCTA)}>{item.title}</Button>
              {/* A simple nested menu one level. */}
              {!item?.submenu?.items && item?.items && (
                <ul className={classNames(
                  "absolute bg-white px-3 z-10 max-lg:transition-[left] max-lg:duration-500 lg:transition-all ease-linear duration-200 flex gap-4 flex-col drop-shadow bg-hygienaLight/80 backdrop-blur-sm",
                    "max-lg:inset-0 max-lg:justify-center max-lg:gap-4 max-lg:min-w-full max-lg:pt-4 max-lg:pb-8",
                    {"lg:max-h-0 lg:overflow-hidden lg:min-h-0 max-lg:left-[100%] hidden": !visible},
                    {"transition-all duration-200 pb-8 pt-4 max-lg:left-0": visible},
                )}>
                  {item.items.map(child => (
                    <li key={child.id}>
                      <div role="presentation">
                        <div
                          onClick={() => {setActiveSubmenu(""); setMenuVisible("");}}
                          role="presentation"
                          className={classNames(
                            "absolute lg:hidden top-8 right-6 text-2xl cursor-pointer",
                          )}>
                          <div className="h-1 w-8 bg-primary rounded-md rotate-45 absolute right-0 cursor-pointer" role="presentation" />
                          <div className="h-1 w-8 bg-primary rounded-md -rotate-45 absolute right-0 cursor-pointer" role="presentation" />
                        </div>
                        <Link title={child.title} href={child.url ?? "/"} prefetch={false} role="link" className={classNames(
                          "top-nav-link hover:bg-primary hover:text-white py-2 px-2 rounded-sm",
                          {"text-primary": child?.url === activePath}
                        )}>{child.title}</Link>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {/* Using taxonomy as a submenu, render a more complex variety. */}
              {item?.submenu?.items?.length ? (
                <SubMenu callback={setMenuVisible}
                         utilityItems={item?.utilityItems}
                         submenu={item.submenu}
                         activePath={activePath}
                         visible={visible} activeSubmenu={activeSubmenu === item?.id}
                         setActiveSubmenu={setActiveSubmenu} />
              ): <></>}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
