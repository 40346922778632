import classNames from "classnames";
import HygienaSpinner from "public/hygiena-spinner.svg";

export function LoadingSpinner ({ className }: { className?: string }) {
  return <HygienaSpinner className={classNames("lds-dual-ring", className ?? "")} />
}

export function LoadingSpinnerOverlay ({ loading, className, spinnerClassName, spinnerContainerClassName, fixed }: {
  loading?: boolean
  className?: string
  spinnerClassName?: string
  spinnerContainerClassName?: string
  fixed?: boolean
}) {
  return (
    <div className={classNames(
      "bg-gray-50/50 h-full w-full -z-50 opacity-0 transition-all duration-500 loading-spinner",
      {
        "hidden": !loading,
        "!z-50 !opacity-100": loading,
        "absolute": !fixed,
        "fixed inset-0": fixed,
      },
      className ?? "",
    )}>
      <div className={classNames("flex h-full w-full justify-center loading-spinner", spinnerContainerClassName ?? "")}>
        <LoadingSpinner className={classNames("scale-150 top-64", spinnerClassName ?? "", {
          "sticky": !fixed,
          "opacity-80 !h-[120px] !w-[120px] m-auto": fixed,
        })}/>
      </div>
    </div>
  )
}