/**
 * Searches an index.
 *
 * @param index
 *   The index to search.
 * @param query
 *   A query string without the ? prefix.
 * @param locale
 *   The current locale i.e. "en"
 * @param defaultLocale
 *   The defaultLocale i.e. "en"
 */
export async function searchIndex(index: string, query?: string, locale?: string, defaultLocale?: string) {
  let data = {} as any;
  let ok = false;

  try {
    let path = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL;
    // Add the locale prefix if not en.
    if (locale && locale !== defaultLocale) {
      path = `${path}/${locale}`;
    }
    path = `${path}/jsonapi/index/${index}`;
    // Add the query string.
    if (query?.length) {
      path = `${path}?${query}`;
    }
    const res = await fetch(`/api/search/all`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Pragma": "no-cache",
        "Expires": "0"
      },
      body: JSON.stringify({
        index,
        query,
        locale,
      }),
      cache: "reload",
    });
    data = await res.json();
    data = data?.data ?? {};
    ok = true;
  }
  catch(error) {
    console.log(error.message);
  }

  return {ok, data};
}