import classNames from "classnames";
import {Link} from "../atoms/link";
import {MediaImage} from "../templates/media/media--image";
import {FormattedText} from "../formatted-text";
import {useContext} from "react";
import {Dictionary} from "@/types/hygiena-types";
import {DictionaryContext} from "@/context/dictionary-context";

export function WidgetHeaderSearchResults ({searchResults, searchActive, isSticky, itemCallback, searchResultsCount, submitCallback}) {
  const t = useContext<Dictionary>(DictionaryContext);

  return (
    <div className={classNames(
      "w-full absolute bg-white top-20 drop-shadow-md z-10 transition-all duration-200 overflow-hidden max-md:hidden header-search-results",
      "bg-gradient-to-br from-white bg-hygienaLight/60 backdrop-blur-md px-6",
      {"h-0": !searchResults.length || !searchActive},
      {"min-h-full py-6 pr-12": searchResults.length && searchActive},
      {"top-14": isSticky}
    )}>
      <div className="flex flex-col gap-4">
        {searchResults && searchResults.length ? (searchResults.map((item) => (
            <div key={item.id} onClick={itemCallback}>
              {item?.path?.alias && (
                <Link
                  href={item?.path?.alias ?? "/"}
                  className="text-black block overflow-auto no-underline rounded-md hover:text-primary transition-all duration-500"
                >
                  <div className="text-lg font-bold flex items-start gap-2">
                    {item?.field_product_image?.length ? (
                      <MediaImage
                        media={item?.field_product_image?.[0]}
                        priority
                        width={160}
                        height={80}
                        sizes="10vw"
                        style={{height: "80px", width: "160px", minWidth: "160px", objectFit: "contain", objectPosition: "center top"}}
                      />
                    ): <></>}
                    <div>
                      <h4>
                        {item?.field_product_brand?.name ? (
                          <>{item?.field_product_brand?.name ?? ""}{item?.field_product_brand?.field_brand_suffix ?? ""} {item?.title}</>
                        ): <>{item.title}</>}
                      </h4>
                      {item?.body?.summary && (
                        <p data-cy="summary" className="font-light text-black">
                          {item?.body?.summary.slice(0, 195)}...
                        </p>
                      )}
                      {!item?.body?.summary && item?.body?.processed && (
                        <p data-cy="summary" className="font-light text-black [&>p]:inline">
                          {item?.field_product_variations?.[0]?.field_var_product_number?.length  && item?.field_product_variations?.[0]?.field_var_product_number[0] !== '-'
                            ? (
                              <>
                                {item.field_product_variations.map((variation, index) => (
                                  <span key={`${item.id}-product-numbers-${index}`}>{variation.field_var_product_number.join(', ')}{ index < item.field_product_variations.length -1 && ', '}</span>
                                ))}
                                <span> - </span>
                              </>
                            )
                            : <></>}
                          <FormattedText processed={`${item?.body?.processed.slice(0, 150)}...`} />
                        </p>
                      )}
                    </div>
                  </div>
                </Link>
              )}
            </div>
          )))
          : <></>}
        {searchActive && searchResultsCount ? (
          <div className="text-center text-sm text-primary cursor-pointer" onClick={event => {submitCallback && submitCallback(event, true)}}>
            {searchResultsCount} {searchResultsCount > 1 ? `${t?.search?.results}`.toLowerCase() : `${t?.search?.result}`.toLowerCase()}
          </div>
        ): <></>}
      </div>
    </div>
  );
}