import {Link} from "../../atoms/link";
import {DrupalMenuLinkContent} from 'next-drupal';
import {LanguageSelect} from "../../molecules/language-select";
import {TranslatedUrls} from "../../../types/hygiena-types";

interface UtilityMenuProps {
  menu?: DrupalMenuLinkContent[];
  entityInfo?: {
    id?: string,
    type?: string,
  },
  hide?: boolean,
  translatedUrls?: TranslatedUrls | null
}

export function MenuUtility ({ menu, entityInfo, translatedUrls, hide, ...props }: UtilityMenuProps) {
  if (!menu?.length) return <></>

  return (
    <nav data-cy="nav-menu" {...props}>
      <ul className="flex py-2 gap-3">
        {!hide && menu?.map((item, index) => {
          const last = (index + 1) === menu.length;
          return (
            <li key={item.id} className="menu-item">
              <Link href={item?.url ?? "/"} prefetch={false} className="hover:underline font-semibold text-sm text-white" title={item.title}>
                {item.title}
                {!last && (
                  <div className="relative inline-block h-4 w-0.5 bg-white top-1 ml-3" />
                )}
              </Link>
            </li>
          );
        })}
        <li className="flex items-center ml-4">
          <LanguageSelect entityInfo={entityInfo} translatedUrls={translatedUrls}/>
        </li>
      </ul>
    </nav>
  );
}
