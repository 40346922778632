import {createContext, ReactElement} from "react";

export type ModalContextType = {
  openModal: boolean|null,
  setOpenModal: Function|null,
  openModalContent: Element|Element[]|ReactElement|ReactElement[]|null,
  setOpenModalContent: Function|null,
  modalOverlayClick: Function|null,
  openSmallModal: boolean|null,
  openSmallModalContent: Element|Element[]|ReactElement|ReactElement[]|null,
  setOpenSmallModal: Function|null,
  setOpenSmallModalContent: Function|null,
}

/**
 * The modal contexts are used to trigger and populate modals and their content.
 */
export const ModalContext = createContext<ModalContextType>({
  openModal: null,
  setOpenModal: null,
  openModalContent: null,
  setOpenModalContent: null,
  modalOverlayClick: null,
  openSmallModal: null,
  openSmallModalContent: null,
  setOpenSmallModal: null,
  setOpenSmallModalContent: null,
});