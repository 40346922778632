// Update this IconType whenever adding a new icon.
import Image, {ImageProps} from "next/image";
import {absoluteURL} from "../../lib/absolute-url";

export type IconType = "aeroplane"|"arrow-down"|"arrow-right"|"arrow-up-right"|"bed"|"calendar"|"certificate"|"check-verified"|"chevron-down"|"chevron-left"|"chevron-right"|"chevron-right-sm"|"chromogenic"|"circle-check"|"clean"|"clock"|"clock-stopwatch"|"close"|"crosshair"|"data-analysis"|"dna"|"dna-detection"|"dna-detection-2"|"dna-extraction"|"documents"|"edit"|"envelope"|"farm"|"file"|"file-attachment"|"file-download"|"file-search-02"|"final-product"|"flask"|"globe"|"globe-americas"|"globe-emea"|"globe-pacific"|"hospital"|"industrial"|"knife-fork"|"lightbulb"|"link-external"|"linkedin"|"linkedin-square"|"microbes"|"mortar"|"pdf"|"pharma"|"phone"|"play"|"play-circle"|"processing"|"raw-milk"|"retail"|"search"|"search-sm"|"sliders"|"smartphone"|"swab"|"target"|"thumbs-up"|"twitter"|"users-three"|"water"|"youtube"|"rocket"|"chat"|"cog"|"download"|"map";

// Colors defined as:
// - white: #fff
// - black: #333
// - accent: #f01849
// - primary: #00aeef
// - light-gray: #d0d0d0
// - dark-gray: #9ca3af
export type IconColor = "white"|"black"|"accent"|"primary"|"light-gray"|"dark-gray";
export function ImageIcon({type, color, height = 50, width = 50, ...props}: {
  type: IconType,
  color: IconColor,
  height: number,
  width: number,
} & Omit<ImageProps, "src">) {
  return <Image src={ImageIconUrl(type, color)} height={height} width={width} {...props} alt={props?.alt ?? ""} />
}

export function ImageIconUrl(type: IconType, color:IconColor){
  // @todo files are currently being stored on Drupal under sites/default/files/icons/{color}/{type}.png
  return absoluteURL(`/sites/default/files/icons/${color}/${type}.png`);
}
