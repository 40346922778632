export const ARTICLE_TEASER_IMAGE_STYLE = "article_teaser";
export const ARTICLE_WIDE_IMAGE_STYLE = "article_wide";
export const HERO_IMAGE_STYLE = "hero_image";
export const THUMBNAIL_IMAGE_STYLE = "thumbnail";
export const PRODUCT_LARGE_IMAGE_STYLE = "product_large";
export const PRODUCT_TEASER_IMAGE_STYLE = "product_teaser";
export const PRODUCT_ZOOM_IMAGE_STYLE = "product_zoom";
export const EVENT_TEASER_IMAGE_STYLE= "event_teaser";
export const LARGE_IMAGE_STYLE = "large";

export const HERO_PLACE_HOLDER_URL = "/sites/default/files/hero-bg-placeholder.webp";