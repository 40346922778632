
export function WidgetModal({openModal, setOpenModalContent, setOpenModal, modalOverlayClick, openModalContent}) {
  return (
    <>
    {openModal && (
      <div className="modal-overlay fixed h-full bg-hygienaLight/30 backdrop-blur-sm w-full inset-0 z-[9999] flex justify-center items-center animate-fade-in" onClick={modalOverlayClick}>
        <div className="mx-auto w-[90%] h-[90%] min-h-[90%] bg-white inset-0 pt-3 px-4 flex flex-col drop-shadow-md rounded-md relative">
          <div className="text-right absolute right-4">
            <div className="inline-block cursor-pointer relative h-6 w-3" onClick={() => {setOpenModalContent(<></>); setOpenModal(false)}}>
              <div className="bg-gray-600 h-6 w-1 rounded-md rotate-45 absolute top-0"/>
              <div className="bg-gray-600 h-6 w-1 rounded-md -rotate-45 absolute top-0"/>
            </div>
          </div>
          <div className="flex-grow p-6 h-full">
            {openModalContent}
          </div>
        </div>
      </div>
    )}
    </>
  )
}

export function WidgetModalSmall({openSmallModal, openSmallModalContent, setOpenSmallModal, setOpenSmallModalContent, modalOverlayClick}) {
  return (
    <>
      {openSmallModal && (
        <div className="modal-overlay fixed min-h-full bg-hygienaLight/30 backdrop-blur-sm w-full inset-0 z-[9999] flex justify-center items-center animate-fade-in" onClick={modalOverlayClick}>
          <div className="mx-auto w-full max-md:h-full md:w-[60%] min-h-[40%] bg-white inset-0 pt-3 px-4 flex flex-col drop-shadow-md rounded-md">
            <div className="text-right">
              <div className="inline-block cursor-pointer relative h-6 w-3" onClick={() => {setOpenSmallModalContent(<></>); setOpenSmallModal(false)}}>
                <div className="bg-gray-600 h-6 w-1 rounded-md rotate-45 absolute top-0"/>
                <div className="bg-gray-600 h-6 w-1 rounded-md -rotate-45 absolute top-0"/>
              </div>
            </div>
            <div className="flex-grow p-6">
              {openSmallModalContent}
            </div>
          </div>
        </div>
      )}
    </>
  )
}